import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { MeasureFavoriteDto } from "api-shared";
import { apiDelete, apiPost } from "../../lib/api";
import { MeasureDetailQueryKeys } from "./detail";
import { MeasureListKeys } from "./list";

const MEASURES_PATH = "measures";

interface IMeasureFavorite {
    id: number;
    column?: number;
}

export const useAddMeasuresFavorite = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: IMeasureFavorite) => apiPost<MeasureFavoriteDto>(`${MEASURES_PATH}/${id}/favorite`),
        onSuccess: (response, { column }) => {
            if (column) {
                queryClient.invalidateQueries(MeasureListKeys.desk(column));
            }
            queryClient.invalidateQueries(MeasureListKeys.list());
            queryClient.invalidateQueries(MeasureDetailQueryKeys.all);
        },
    });
};

export const useRemoveMeasureFavorite = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: IMeasureFavorite) => apiDelete(`${MEASURES_PATH}/${id}/favorite`),
        onSuccess: (response, { column }) => {
            if (column) {
                queryClient.invalidateQueries(MeasureListKeys.desk(column));
            }
            queryClient.invalidateQueries(MeasureListKeys.list());
            queryClient.invalidateQueries(MeasureDetailQueryKeys.all);
        },
    });
};

export const useToggleFavoriteMeasure = (measureId: number, isFavorite: boolean, column?: number) => {
    const addFavoriteMeasure = useAddMeasuresFavorite();
    const removeFavoriteMeasure = useRemoveMeasureFavorite();

    return useCallback(() => {
        if (isFavorite) {
            removeFavoriteMeasure.mutate({ id: measureId, column });
        } else {
            addFavoriteMeasure.mutate({ id: measureId, column });
        }
    }, [measureId, isFavorite, column, addFavoriteMeasure, removeFavoriteMeasure]);
};
