import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/StarRounded";
import { IconButton, styled, SvgIconProps } from "@mui/material";
import { TFunction } from "i18next";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";

const LightStarOutlineIcon = styled(StarOutlineIcon)(({ theme }) => ({
    color: theme.palette.action.disabled,
}));

interface IFavoriteIconProps {
    value: boolean;
    updateValue?: (newValue: boolean) => void;
    translate?: TFunction;
    className?: string;
    iconSize?: SvgIconProps["fontSize"];
}

const FavoriteIcon = ({ className, value, updateValue, translate, iconSize = "medium" }: IFavoriteIconProps) => {
    const icon = value ? <StarIcon fontSize={iconSize} color="secondary" /> : <LightStarOutlineIcon fontSize={iconSize} />;

    if (typeof updateValue !== "function") {
        return icon;
    }
    const tooltip = value ? translationKeys.VDLANG_METHOD_UNFAVORITE_BUTTON_LABEL : translationKeys.VDLANG_METHOD_FAVORITE_BUTTON_LABEL;

    return (
        <Tooltip title={translate !== undefined ? translate(tooltip) : ""}>
            <IconButton
                size="small"
                className={className}
                onClick={(e) => {
                    updateValue(!value);
                    e.preventDefault();
                }}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default FavoriteIcon;
